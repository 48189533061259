import type { Promotion, PromotionsResponse } from "@/types";

const normalize = (
	promotions: Array<Promotion>,
	isSweepStakes: boolean,
	decorator: ReturnType<typeof createDecorator>
) => {
	if (!promotions) {
		return [];
	}

	return promotions.reduce(
		(accumulator: Array<Promotion>, promotion) => [...accumulator, decorator(promotion, isSweepStakes)],
		[]
	) as Array<ReturnType<typeof decorator>>;
};

const transform =
	(decorator: ReturnType<typeof createDecorator>) => (data: PromotionsResponse, isSweepStakes: boolean) => {
		const promotions = normalize(data?.payload?.promotions as Array<Promotion>, isSweepStakes, decorator);

		const availableSoon = normalize(data?.payload?.available_soon as Array<Promotion>, isSweepStakes, decorator);
		return {
			promotions,
			availableSoon
		};
	};

const defaultData = (): ReturnType<ReturnType<typeof transform>> => ({
	promotions: Array(4).fill({
		skeleton: true
	}),
	availableSoon: Array(4).fill({
		skeleton: true
	})
});

const usePromotionsPage = ({
	immediate = true,
	server = false,
	cached = true
}: { immediate?: boolean; server?: boolean; cached?: boolean } = {}) => {
	const { t } = useT();
	const { isSweepStakes } = useSwitchMode();

	const { data: promoData, refresh } = useAsyncFetch({
		path: "/rest/page/promotions/",
		method: "get",
		options: {
			cached: cached === false ? undefined : true,
			immediate,
			server
		}
	});

	const promotionsData = computed(() => {
		if (!promoData.value) {
			return defaultData();
		}

		return transform(createDecorator(t))(promoData.value, isSweepStakes.value);
	});

	return {
		refresh,
		promotionsData
	};
};

export default usePromotionsPage;
